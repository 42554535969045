import {
	Button,
	Errors,
	FieldDescription,
	Form,
	Heading,
	HorizontalRule,
	Input,
	Label,
	MessageBar,
	TextField,
} from '@troon/ui';
import { createEffect, createSignal, Match, Show, Suspense, Switch } from 'solid-js';
import { clientAction } from '@troon/api-client';
import { useSubmission } from '@solidjs/router';
import { IconCheck } from '@troon/icons/check';
import { IconCloseCircle } from '@troon/icons/close-circle';
import { IconCircleCheck } from '@troon/icons/circle-check';
import { Content } from '../../../components/main-content';
import { useUser } from '../../../providers/root';

export default function Account() {
	const user = useUser();
	return (
		<Content>
			<Suspense>
				<Heading as="h1">My account</Heading>

				<div class="relative flex max-w-6xl flex-col gap-8 rounded border border-neutral bg-white p-4 xl:gap-12 xl:p-8">
					<div class="flex flex-wrap justify-stretch gap-8">
						<div class="shrink-0 grow-0 xl:basis-1/2">
							<Heading as="h2" size="h4">
								Name
							</Heading>
							<p>Your name will be used on tee time reservations.</p>
						</div>

						<div class="flex grow flex-col gap-8">
							<TextField name="firstName">
								<Label>First name</Label>
								<Input readonly value={user()?.firstName} />
							</TextField>
							<TextField name="lastName">
								<Label>Last name</Label>
								<Input readonly value={user()?.lastName} />
							</TextField>
						</div>
					</div>

					<HorizontalRule />

					<div class="flex flex-wrap justify-stretch gap-8">
						<div class="shrink-0 grow-0 xl:basis-1/2">
							<Heading as="h2" size="h4">
								Password
							</Heading>
							<p>Your password is used for logging into your Troon Rewards account.</p>
						</div>

						<UpdatePassword />
					</div>
				</div>
			</Suspense>
		</Content>
	);
}

function UpdatePassword() {
	const [passwordsMatch, setPasswordsMatch] = createSignal<boolean | null>(null);
	const [oldPasswordRef, setOldPasswordRef] = createSignal<HTMLInputElement>();
	const [passwordRef, setNewPasswordRef] = createSignal<HTMLInputElement>();
	const [confirmRef, setConfirmRef] = createSignal<HTMLInputElement>();
	const submission = useSubmission(updatePasswordAction);

	createEffect(() => {
		if (submission.result?.success) {
			oldPasswordRef()!.value = '';
			passwordRef()!.value = '';
			confirmRef()!.value = '';
		}
	});

	return (
		<Form action={updatePasswordAction} class="grow">
			<TextField name="oldPassword">
				<Label>Current password</Label>
				<Input type="password" ref={setOldPasswordRef} />
			</TextField>

			<TextField name="newPassword">
				<Label>New password</Label>
				<Input type="password" ref={setNewPasswordRef} />
			</TextField>

			<TextField name="confirm-password">
				<Label>Confirm new password</Label>
				<Input
					type="password"
					onInput={(e) => {
						const ref = passwordRef();
						if (ref) {
							setPasswordsMatch(e.currentTarget.value === ref.value);
						}
					}}
					ref={setConfirmRef}
				/>
				<FieldDescription>
					<div
						classList={{
							'text-red-600': passwordsMatch() === false,
							'text-green-600': !!passwordsMatch(),
						}}
					>
						<Switch fallback={<>&nbsp;</>}>
							<Match when={passwordsMatch()}>
								<IconCheck /> Passwords match
							</Match>
							<Match when={passwordsMatch() === false}>
								<IconCloseCircle /> Passwords do not match
							</Match>
						</Switch>
					</div>
				</FieldDescription>
			</TextField>

			<Errors />

			<Show when={submission.result?.success}>
				<MessageBar appearance="brand" icon={IconCircleCheck}>
					Your password has been updated.
				</MessageBar>
			</Show>

			<div class="flex justify-end">
				<Button disabled={!passwordsMatch()} type="submit" class="grow-0">
					Update Password
				</Button>
			</div>
		</Form>
	);
}

const updatePasswordAction = clientAction('POST', '/v0/operator/auth/change-password');
